import { Trans, t } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faRankingStar } from "@fortawesome/pro-light-svg-icons";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Seo from "../../../components/seo";
import SecondaryButton from "../../../components/SecondaryButton";
import HeroLanding from "../../../components/HeroLanding";
import ProductModal from "../../../components/ProductModal";
import { theme } from "../../../themeV2";
import ArrowButton from "../../../components/ArrowButton";
import LandingsTabs from "../../../components/landings/LandingsTabs";
import ImageComponent from "../../../components/landings/ImageComponent";
import DefaultSection from "../../../components/landings/DefaultSection";
import heroImg from "../../../images/segments/fund-houses/hero.png";
import logo1 from "../../../images/segments/fund-houses/logo-ccla.png";
import logo2 from "../../../images/segments/fund-houses/logo-fidelity.png";
import logo3 from "../../../images/segments/fund-houses/logo-franklin.png";
import logo4 from "../../../images/segments/fund-houses/logo-nikko.png";
import sec2Img from "../../../images/segments/fund-houses/sec-2.png";
import sec3Img from "../../../images/segments/fund-houses/sec-3.png";
import sec4Tab1Img from "../../../images/segments/fund-houses/sec-4_tab-1.png";
import sec4Tab2Img from "../../../images/segments/fund-houses/sec-4_tab-2.png";
import sec6Tab1Img from "../../../images/segments/fund-houses/sec-6_tab-1.png";
import sec6Tab2Img from "../../../images/segments/fund-houses/sec-6_tab-2.png";
import sec7Img from "../../../images/segments/fund-houses/sec-7.png";
import sec8Img from "../../../images/segments/fund-houses/sec-8.png";
import arrowRight from "../../../images/segments/fund-houses/arrow-right.png";
import arrowLeft from "../../../images/segments/fund-houses/arrow-left.png";

const mainColor = theme.palette.colors.yellow.main;

const metadata = {
  title: "Fund Houses",
  description: "Allfunds serves as a strategic partner for Fund Houses, offering product creation, access to the largest distribution network, and a suite of sophisticated digital tools.",
};

const CASE_STUDIES_ITEMS = [
  {
    img: logo1,
    title: t`Customer Relationship Management `,
    description: t`Enhance client engagement and advance its digital capabilities by providing a web portal  for clients and organisations to monitor and report on their investment portfolios.`,
  },
  {
    img: logo2,
    title: t`New Markets`,
    description: t`“Using Allfunds Navigator, XXXX identified new countries for expansion and discussed where to hire new sales staff to capitalize on this momentum.”`,
  },
  {
    img: logo3,
    title: t`ESG Offering`,
    description: t`Helped an Asset Manager reshape two ESG mutual funds focused on Social and Environmental themes targeting specific Sustainable Development Goals.`,
  },
  {
    img: logo4,
    title: t`Bridging Global Markets`,
    description: t`Based in HK, we connect China with the world, unlocking European market access with UCITS vehicles, cost-effective platform access, and extensive distribution coverage.`,
  },
];

const SEC_2_ITEMS_LEFT = [
  {
    color: theme.palette.colors.green.main,
    title: t`Data Analytics & Marketing`,
    text: <Trans>Uncover <strong>opportunities worldwide</strong> with real data insights and gain visibility in the world’s largest fund digital ecosystem</Trans>,
  },
  {
    color: theme.palette.colors.purple.main,
    title: t`B2C Channles`,
    text: <Trans>Unlock new distribution channels with a digital <strong>investment portal for the end-client</strong>, improving user experience and digitalizing client relationships</Trans>,
  },
  {
    color: theme.palette.colors.blue.main,
    title: t`Distribution`,
    text: <Trans>With a single agreement, Allfunds offers Fund Houses access to the <strong>largest distribution network</strong> for all their products: mutual funds, private markets, and ETPs.</Trans>,
  },
];

const SEC_2_ITEMS_RIGHT = [
  {
    color: theme.palette.colors.yellow.main,
    title: t`ManCo Services`,
    text: <Trans>Dedicated solution to create <strong>your own fund range or UCITs wrappers</strong> in Luxembourg or Dublin with an expert team.</Trans>,
  },
  {
    color: theme.palette.colors.orangeLight.main,
    title: t`Regulatory Requirements`,
    text: <Trans><strong>Fund Registration</strong> and <strong>Regulatory Reporting </strong>to help you navigate regulations and access new markets efficiently with expert support and innovative technology.</Trans>,
  },
  {
    color: theme.palette.colors.blueLight.main,
    title: t`ESG Complaint`,
    text: <Trans>Comprehensive set of solutions, including proprietary ESG ratings, data, and tailored solutions to help you <strong>navigate the complex ESG landscape.</strong></Trans>,
  },
];

const SEC_4_TABS_ITEMS = [
  {
    img: sec4Tab1Img,
    tabTitle: t`Fund registation`,
    title: t`Boost your distribution strategy and access new markets`,
    description: (
      <Typography><Trans>
        Allfunds offers comprehensive support to expand your fund distribution to new markets, providing you with the crucial information and registration services needed to successfully sell your funds globally.
      </Trans></Typography>
    ),
  },
  {
    img: sec4Tab2Img,
    tabTitle: t`Regulatory Reporting`,
    title: t`Enhance compliance and streamline publication processes`,
    description: (
      <Typography><Trans>
        Allfunds’ Regulatory Reporting platform ensures compliance with regulations and streamlines efficient document distribution, including KID PRIIPs, EMT, EPT, EET, Factsheets, and ESG reports. (Principal Adverse Impact and EU Taxonomy analysis provided by MainStret Partner).
      </Trans></Typography>
    ),
  },
];

const SEC_5_ITEMS = [
  {
    order: { xs: "1", sm: "1", md: "1"},
    title: t`Fund engineering`,
    description: t`Tailored advice on fund creation and product design, local offices of Allfunds or on-site in Luxembourg`,
  },
  {
    order: { xs: "2", sm: "2", md: "2"},
    title: t`On-boarding process`,
    description: t`Coordination of the entire process across all partners including regulatory and legal requirements`,
  },
  {
    order: { xs: "3", sm: "4", md: "3"},
    title: t`Operational set-up`,
    description: t`Full coordination with all parties involved (fund administrator, depositary, investment manager, distributor, auditor, tax adviser..)`,
  },
  {
    order: { xs: "4", sm: "3", md: "4"},
    title: t`Registration`,
    description: t`Registration in different countries for international distribution`,
  },
  {
    order: { xs: "5", sm: "5", md: "5"},
    title: t`Go Live`,
    description: t`Active distribution support, market intelligence, events and sales force on the ground`,
  },
];

const SEC_6_TABS_ITEMS = [
  {
    img: sec6Tab1Img,
    title: t`ESG Analytics`,
    description: (
      <Stack spacing={2}>
        <Typography><Trans>
          A comprehensive ESG audit tool to assess portfolios of Funds, ETFs, Equities, Corporate Bonds, Government Bonds,
          Green and Social Bonds.
        </Trans></Typography>
        <Box
          display="grid"
          columnGap={3}
          rowGap={1}
          width={{ xs: "100%", sm: "fit-content" }}
          gridTemplateColumns={{ xs: "1fr 1fr", sm: "repeat(4, 1fr)", md: "1fr 1fr" }}
        >
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>+90.000</Typography>
            </Stack>
            <Typography><Trans>Funds & ETFs</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>415</Typography>
            </Stack>
            <Typography><Trans>Funds Houses</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>4.400</Typography>
            </Stack>
            <Typography><Trans>Green Bonds</Trans></Typography>
          </Stack>
          <Stack justifyContent="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon icon={faArrowUp} style={{ color: mainColor, fontSize: "24px" }} />
              <Typography style={{ color: mainColor, fontSize: "24px", fontWeight: 700 }}>8.500</Typography>
            </Stack>
            <Typography><Trans>Unique Strategies</Trans></Typography>
          </Stack>
        </Box>
      </Stack>
    ),
  },
  {
    img: sec6Tab2Img,
    title: t`ESG Portfolio and Advisory`,
    description: (
      <Stack spacing={1}>
        <Typography><Trans>
          Our comprehensive service encompasses
        </Trans></Typography>
        <ul>
          <li><Trans>Support in creating or restructuring ESG-focused products</Trans></li>
          <li><Trans>Assistance in drafting operational documents</Trans></li>
          <li><Trans>Delivery of monthly universes/portfolio reporting</Trans></li>
          <li><Trans>ESG training for your salesforce and end investors</Trans></li>
          <li><Trans>Commercial and marketing support, empowering you to excel in the ESG space.</Trans></li>
        </ul>
      </Stack>
    ),
  },
];

function FundHouses() {
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SEC_4_TABS_ITEMS[0].tabTitle);
  const selectedItem = SEC_4_TABS_ITEMS.find((item) => item.tabTitle === selectedTab);
  const [selectedTab2, setSelectedTab2] = useState(SEC_6_TABS_ITEMS[0].title);
  const selectedItem2 = SEC_6_TABS_ITEMS.find((item) => item.title === selectedTab2);

   const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderArrowButton = (link) => {
    return (
      <ArrowButton
        isDark
        variant="text"
        color="secondary"
        title="Know more"
        extraStyles={{ px: "0 !important" }}
        contentColor={theme.palette.secondary.main}
        onClick={() => window.open(link, "_blank")}
      />
    );
  };

  const renderArrowImg = (index) => {
    const arrowMobile = (index === 2 || index === 3) ? arrowLeft : arrowRight;
    const arrowImg = isTablet ? arrowMobile : arrowRight;
    return (
      <img src={arrowImg} alt="Allfunds Navigator | Allfunds" style={{ position: "absolute", top: 0, left: 0, objectFit: "contain" }} width="100%" height="100%" />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        kind={t`Fund Houses`}
        title={t`Maximize distribution efforts and empower your business growth`}
        description={
          <Trans>
            Allfunds serves as a strategic partner for Fund Houses, offering{" "}
            <strong>product creation</strong>, access to the{" "}
            <strong>largest distribution network</strong>, and a suite of{" "}
            <strong>sophisticated digital tools</strong>.<br />
            <br />
            Streamline your operations, unlock valuable market intelligence, and
            make informed, data-driven decisions to optimize distribution,
            enhance visibility, and achieve sustainable growth.
          </Trans>
        }
        grid={{
          columnLeft: { md: 7 },
          columnRight: { md: 5 },
        }}
        multimedia={heroImg}
        kindColor={mainColor}
        handleOnclickInfo={() => setShowModal(true)}
        buttonJustify="flex-start"
      />
      <Container component="section" pb={{ xs: 2, md: 10, lg: 8 }}>
        <Box
          width="fit-content"
          m={{ xs: "0 auto -90px", md: "0 auto -102.5px" }}
        >
          <CaseStudiesStack>
            <FontAwesomeIcon icon={faRankingStar} />
            <Typography>
              Case
              <br />
              Studies
            </Typography>
            <FontAwesomeIcon icon={faArrowDown} />
          </CaseStudiesStack>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{
            backgroundColor: theme.palette.colors.white.dark,
            padding: "124px 24px 24px",
            marginLeft: "-16px!important",
            borderRadius: "14px",
          }}
        >
          {CASE_STUDIES_ITEMS.map(({ img, title, description }) => (
            <Grid item xs={12} sm={6} md={3}>
              <Stack spacing={2} component="article">
                <ImageComponent
                  src={img}
                  alt={title + " | Allfunds"}
                  imgFit="contain"
                  extraStyles={{
                    width: "164px",
                    height: 50,
                  }}
                  extraImgStyles={{
                    objectPosition: "left",
                    mixBlendMode: "multiply",
                  }}
                />
                <Stack spacing={1}>
                  <Typography fontWeight={700}>{title}</Typography>
                  <Typography>{description}</Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
      <DefaultSection
        textAlign="center"
        pretitleAlign="center"
        pretitle={t`Unlocking Growth Opportunities`}
        pretitleColor={mainColor}
        title={t`Unique one-stop offering from product creation to client distribution`}
        description={
          <>
            <Stack alignItems="center">
              <Typography width={{ md: "80%", lg: "70%" }}>
                <Trans>
                  Streamlined fund creation and distribution, robust regulatory
                  compliance, and powerful data analytics empower fund houses to
                  thrive in a competitive global market
                </Trans>
              </Typography>
            </Stack>
            <Box pt={1} display="flex" justifyContent={{ xs: "center" }}>
              {renderRequestButton()}
            </Box>
            <Grid
              container
              xs={12}
              pt={{ xs: 5, sm: 7, md: 9 }}
              justifyContent="center"
              gap={{ xs: 4, md: 0 }}
              textAlign="initial"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                direction="column"
                gap={{ xs: 1, md: 7, lg: 9 }}
              >
                {SEC_2_ITEMS_LEFT.map(({ color, title, text }, key) => (
                  <Stack
                    spacing={1}
                    alignItems={{ xs: "center", md: "flex-end" }}
                    textAlign={{ xs: "center", md: "left" }}
                    sx={{
                      transform: {
                        md:
                          key === 1 ? "translateX(-48px)" : "translateX(48px)",
                      },
                    }}
                  >
                    <Typography fontWeight={700} fontSize="20px">
                      {title}
                    </Typography>
                    <Stack
                      component="span"
                      width={66}
                      height="2px"
                      backgroundColor={color}
                    ></Stack>
                    <Typography textAlign={{ md: "right" }}>{text}</Typography>
                  </Stack>
                ))}
              </Grid>
              <Grid item xs={12} sm={8} md={4}>
                <ImageComponent
                  imgFit="contain"
                  alt="Unlocking Growth Opportunities"
                  src={sec2Img}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                direction="column"
                gap={{ xs: 1, md: 7, lg: 9 }}
              >
                {SEC_2_ITEMS_RIGHT.map(({ color, title, text }, key) => (
                  <Stack
                    spacing={1}
                    alignItems={{ xs: "center", md: "flex-start" }}
                    textAlign={{ xs: "center", md: "left" }}
                    sx={{
                      transform: {
                        md:
                          key === 1 ? "translateX(48px)" : "translateX(-48px)",
                      },
                    }}
                  >
                    <Typography fontWeight={700} fontSize="20px !important">
                      {title}
                    </Typography>
                    <Stack
                      component="span"
                      width={66}
                      height="2px"
                      backgroundColor={color}
                    ></Stack>
                    <Typography textAlign={{ md: "left" }}>{text}</Typography>
                  </Stack>
                ))}
              </Grid>
            </Grid>
          </>
        }
        containerPy={0}
        pt={{ xs: 8, md: 10, lg: 8 }}
      />
      <DarkStack
        component="span"
        color={theme.palette.colors.white.main}
        mt={{ xs: 8, md: 14.5, lg: 10 }}
        borderRadius="48px 48px 0 0"
        backgroundColor={theme.palette.colors.blue.dark}
      >
        <Typography
          textAlign="center"
          fontWeight={700}
          fontSize={{ xs: "36px", sm: "48px" }}
          color={theme.palette.colors.white.main}
          mt={{ xs: 2, sm: 5 }}
        >
          <Trans>Our offer</Trans>
        </Typography>
        <DefaultSection
          containerPy={0}
          pt={{ xs: 2, md: 5 }}
          pb={{ xs: 8, md: 10, lg: 8 }}
          pretitle={t`Allfunds Navigator`}
          pretitleColor={mainColor}
          title={t`Actionable data insights for the pursuit of new opportunities`}
          description={
            <Stack spacing={2}>
              <Typography>
                <Trans>
                  Allfunds Navigator offers data-driven intelligence across a{" "}
                  <strong>€4.5 trillion universe</strong>, empowering fund
                  distributors to uncover hidden opportunities and make informed
                  decisions. Identify ideal distributors, pinpoint areas ripe
                  for investment, and optimize your distribution strategy.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  With Allfunds Navigator, you can pursue{" "}
                  <strong>
                    replacement opportunities, defend your assets,
                  </strong>{" "}
                  and confidently{" "}
                  <strong>expand into new jurisdictions.</strong> Leverage
                  real-time insights, customizable reporting, and an intuitive
                  interface to gain a competitive edge in today's evolving
                  market.
                </Trans>
              </Typography>
            </Stack>
          }
          multimedia={sec3Img}
          buttons={
            <>
              {renderRequestButton()}
              {renderArrowButton()}
            </>
          }
        />
        <DefaultSection
          pretitle={t`Regulatory Services`}
          direction="row-reverse"
          pretitleColor={mainColor}
          title={t`Simplify Compliance and Expand Reach Globally`}
          description={
            <>
              <Stack spacing={1} pb={1}>
                <Typography>
                  <Trans>
                    Allfunds streamlines regulatory reporting and fund
                    registration, helping you navigate complex regulations and
                    access new markets efficiently with expert support and
                    innovative technology
                  </Trans>
                </Typography>
              </Stack>
              <LandingsTabs
                value={selectedTab}
                valueSet={setSelectedTab}
                tabs={SEC_4_TABS_ITEMS.map((item) => item.tabTitle)}
                tabColor={mainColor}
                tabsAlign="flex-start"
                tabBgColor={theme.palette.colors.yellow.light}
              />
              <Stack spacing={2}>
                <Typography variant="h3">{selectedItem.title}</Typography>
                <Typography>{selectedItem.description}</Typography>
              </Stack>
            </>
          }
          multimedia={selectedItem.img}
          buttons={
            <>
              {renderRequestButton()}
              {renderArrowButton()}
            </>
          }
        />
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          pretitle={t`ManCo Services`}
          pretitleColor={mainColor}
          title={t`Dedicated solution to create your own fund range or ETF wrapper`}
          description={
            <Grid
              container
              spacing={2}
              ml="-24px !important"
              pb={1}
              justifyContent="center"
            >
              {SEC_5_ITEMS.map(
                ({ order, title, description }, index) => (
                  <Grid item xs={12} sm={6} md={2.4} order={order}>
                    <Stack spacing={2} textAlign="left">
                      <Stack
                        alignItems="center"
                        minWidth="100%"
                        sx={{position: "relative"}}
                      >
                        {renderArrowImg(index)}
                        <Stack
                          component="span"
                          width="36px"
                          height="36px"
                          alignItems="center"
                          borderRadius="100%"
                          justifyContent="center"
                          backgroundColor={mainColor}
                        >
                          <Typography
                            fontSize="20px"
                            fontWeight={700}
                            color={`${theme.palette.colors.blue.dark}!important`}
                          >
                            {index + 1}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography fontWeight={700}>{title}</Typography>
                      <Typography>{description}</Typography>
                    </Stack>
                  </Grid>
                ),
              )}
            </Grid>
          }
          buttons={
            <>
              {renderRequestButton()}
              {renderArrowButton()}
            </>
          }
        />
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          pretitle={t`ESG Solutions`}
          pretitleColor={mainColor}
          title={t`Convert ESG from a burden to an opportunity`}
          description={
            <>
              <Stack spacing={1} pb={1}>
                <Typography>
                  <Trans>
                    At Allfunds, ESG isn't just an afterthought - it's
                    seamlessly integrated into our suite of financial solutions.
                  </Trans>
                </Typography>
                <Typography>
                  <Trans>
                    We offer a comprehensive set of solutions, including
                    proprietary ESG ratings, data, and tailored solutions to
                    help you navigate the complex ESG landscape. Partner with us
                    and MainStreet Partners, our trusted ESG expert, to unlock a
                    world of sustainable investment opportunities and turn
                    challenges into competitive advantages.
                  </Trans>
                </Typography>
              </Stack>
              <LandingsTabs
                value={selectedTab2}
                valueSet={setSelectedTab2}
                tabs={SEC_6_TABS_ITEMS.map((item) => item.title)}
                tabColor={mainColor}
                tabBgColor={theme.palette.colors.yellow.light}
              />
              <DefaultSection
                textAlign="left"
                padding="0!important"
                title={selectedItem2.title}
                titleVariant="h3"
                description={selectedItem2.description}
                multimedia={selectedItem2.img}
              />
              <Box display="flex" justifyContent="center" gap={1}>
                {renderRequestButton()}
                {renderArrowButton()}
              </Box>
            </>
          }
        />
        <DefaultSection
          pretitle={t`Fund Insights`}
          pretitleColor={mainColor}
          title={t`Bring your funds closer to the decision makers `}
          description={
            <Typography>
              <Trans>
                Publish everything from opinions and market intelligence to
                reports and research, bringing the narrative directly to
                decision making investment professionals such as fund selectors
                and advisors.
              </Trans>
            </Typography>
          }
          multimedia={sec7Img}
          buttons={
            <>
              {renderRequestButton()}
              {renderArrowButton()}
            </>
          }
        />
        <DefaultSection
          direction="row-reverse"
          pretitle={t`Due Diligence Portal`}
          pretitleColor={mainColor}
          title={t`Fast-track your Due Diligence workflow, from data to reporting`}
          description={
            <Typography>
              <Trans>
                Access accurate, up-to-date Due Diligence material from
                Allfunds’ standard database. Run assessments and generate
                regulation compliant reports that support your fund catalog.
              </Trans>
            </Typography>
          }
          multimedia={sec8Img}
          buttons={
            <>
              {renderRequestButton()}
              {renderArrowButton()}
            </>
          }
        />
      </DarkStack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Bank & Wealth Managers"
          product="fund-houses"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const CaseStudiesStack = styled(Stack)`
            width: 180px;
            height: 180px;
            font-size: 24px;
            position: relative;
            border-radius: 100%;
            justify-content: center;
            border: 12px solid #fff;
            color: ${theme.palette.colors.white.main};
            background-color: ${mainColor};
            ${theme.breakpoints.up("md")} {
            width: 205px;
            height: 205px;
            font-size: 36px;
          }

            p {
            font-weight: 600;
            line-height: 1.1;
            text-align: center;
            margin: 12px 0 8px 0;
                color: ${theme.palette.colors.white.main};
            ${theme.breakpoints.up("md")} {
            font-size: 24px;
          }
          }

            `;

const DarkStack = styled(Stack)`
  p:not(.preTitle) {color: ${theme.palette.colors.white.main};
`;
export default FundHouses;
